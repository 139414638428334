const AIOptions = {
  text: [
    { text: "GPT-4o", value: "gpt-4o" },
    { text: "GPT-4o-mini", value: "gpt-4o-mini" },
    { text: "GPT-3.5-Turbo", value: "gpt-3.5-turbo" },
    { text: "GPT-4", value: "gpt-4" },
  ],

  image: [
    { text: "Flux-Pro-1.1", value: "flux-pro-1.1" },
    { text: "SD-Ultra", value: "sd-ultra" },
    { text: "Flux-pro", value: "flux-pro" },
    { text: "Flux-dev", value: "flux-dev" },
    { text: "Dalle-2", value: "dalle-2" },
    { text: "Dalle-3", value: "dalle-3" },
    { text: "sd3.5-large", value: "sd3.5-large" },
    { text: "sd3.5-large-turbo", value: "sd3.5-large-turbo" },
    { text: "sd3.5-medium", value: "sd3.5-medium" },
    { text: "sd3-large", value: "sd3-large" },
    { text: "sd3-large-turbo", value: "sd3-large-turbo" },
    { text: "sd3-medium", value: "sd3-medium" },
  ],
};

export { AIOptions };
