interface CheckboxProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label: string;
  id?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  onChange,
  checked,
  label,
  id = `checkbox-${Math.random().toString(36).slice(2)}`,
}) => {
  return (
    <button
      className="flex button-checkbox"
      onClick={(e) => onChange(!checked)}
    >
      <div className={`journal-checkbox ${checked ? "checked" : ""}`} id={id} />
      <label className="journal-label" htmlFor={id}>
        {label}
      </label>
    </button>
  );
};
